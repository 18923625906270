.container {
  height: 80px;
  width: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;

  cursor: pointer;

  @media screen and (min-width: 560px) {
    height: 100px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    height: 70px;
    overflow: initial;
  }

  @media screen and (min-width: 1440px) {
    height: 100px;
    overflow: initial;
  }
}

.wrapperEth {
  position: absolute;
  top: 0;
  left: 0;
}

.wrapperYel {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;

  border-radius: 50%;
  background-color: var(--clr-primary-background);

  width: 82px;
  height: 82px;

  @media screen and (min-width: 560px) {
    width: 102px;
    height: 102px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    height: 72px;
    width: 72px;
  }

  @media screen and (min-width: 1440px) {
    width: 102px;
    height: 102px;
  }
}

.currencyWrapper {
  position: absolute;
  top: 0;
  left: 0;

  width: 123px;

  @media screen and (min-width: 560px) {
    width: 156px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    width: 121px;
  }

  @media screen and (min-width: 1440px) {
    width: 156px;
  }
}

.eth,
.yel,
.lp {
  width: 80px;
  height: 80px;

  @media screen and (min-width: 560px) {
    width: 100px;
    height: 100px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    width: 70px;
    height: 70px;
  }

  @media screen and (min-width: 1440px) {
    width: 100px;
    height: 100px;
  }
}

.yel {
  position: absolute;
  top: 5px;
  left: 55%;
  transform: translateX(-50%);
}

.lp {
  position: absolute;
  top: 0;
  right: 0;
}

.arrowsWrapper {
  position: absolute;
  width: 58px;
  height: 100%;
  top: 0;
  left: 48%;

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    width: 48px;
    left: 50%;
  }

  @media screen and (min-width: 1440px) {
    left: 50%;
  }
}
