.container {
  width: 220px;
  height: 214px;
  position: relative;
  cursor: pointer;
}

.puddle {
  position: absolute;
  width: 100%;
  top: 110px;
  left: 0;
}

.firstBubble {
  position: absolute;
  top: 20px;
  right: 0;

  width: 115px;
  height: 115px;

  background-image: url('/icons/metrics/firstBubble.svg');
  background-repeat: no-repeat;
}

.secondBubble {
  position: absolute;
  top: 35px;
  right: 45px;

  width: 120px;
  height: 120px;

  background-image: url('/icons/metrics/secondBubble.svg');
  background-repeat: no-repeat;
}

.thirdBubble {
  position: absolute;
  top: 90px;
  left: 30px;

  width: 60px;
  height: 60px;

  background-image: url('/icons/metrics/thirdBubble.svg');
  background-repeat: no-repeat;
}

.fourBubble {
  position: absolute;
  top: 145px;
  right: 30px;

  width: 44px;
  height: 22px;

  background-image: url('/icons/metrics/fourBubble.svg');
  background-repeat: no-repeat;
}

.fiveBubble {
  position: absolute;
  top: 110px;
  right: 35px;

  width: 35px;
  height: 19px;

  background-image: url('/icons/metrics/fiveBubble.svg');
  background-repeat: no-repeat;
}

.sixBubble {
  position: absolute;
  top: 120px;
  right: 100px;

  width: 35px;
  height: 19px;

  background-image: url('/icons/metrics/sixBubble.svg');
  background-repeat: no-repeat;
}
