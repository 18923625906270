.list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 10;

  @media screen and (min-width: 1024px) {
    gap: 1.5rem;
  }
}

.item {
  text-align: start;

  @media screen and (min-width: 1440px) {
    text-align: end;
  }
}

.value {
  font-weight: 700;
  font-size: 1.5rem;

  @media screen and (min-width: 340px) {
    font-size: 1.75rem;
  }

  @media screen and (min-width: 1024px) {
    font-size: 2rem;
  }
}

.valueSkeleton {
  height: 29px;
  @media screen and (min-width: 340px) {
    height: 34px;
  }

  @media screen and (min-width: 1024px) {
    height: 38px;
  }
}
