.list {
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 1.25rem;
  width: 100%;

  @media screen and (min-width: 560px) {
    align-items: center;
  }

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    align-items: initial;
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1.25rem;

  padding: 1.25rem;
  z-index: 10;

  border-radius: var(--br-16);
  border: 4px solid var(--clr-border-card);
  background-color: var(--clr-primary-background);

  @media screen and (min-width: 560px) {
    width: 528px;
  }

  @media screen and (min-width: 1024px) {
    width: 280px;
  }
}

.wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.value {
  font-size: 2.75rem;
  font-weight: 700;
}

.title {
  font-size: 1.25rem;
  font-weight: 600;
}
