.wrapper {
  width: 100%;
  height: 100%;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 180px;
  height: 200px;
  margin-top: 25px;
  cursor: pointer;
}

.circle {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  z-index: 2;

  background-image: url('/icons/metrics/ellipse.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.centralCircle {
  width: 42px;
  height: 42px;

  top: 34%;
}

.circle1 {
  top: -5%;
  left: 50%;
}

.circle2 {
  top: calc(30% - 30px);
  left: 90%;
}

.circle3 {
  top: calc(75% - 30px);
  left: 90%;
}
.circle4 {
  top: calc(95% - 30px);
  left: 50%;
}
.circle5 {
  top: calc(73% - 30px);
  left: 12%;
}
.circle6 {
  top: calc(30% - 30px);
  left: 11%;
}

.orbits {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-image: url('/icons/metrics/orbits.svg');
  background-repeat: no-repeat;
}
