.list {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  @media screen and (min-width: 1024px) {
    max-width: 565px;
  }
}

.item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--br-24);
  height: 66px;
  padding: 0.5rem 1.125rem 0.5rem 5.75rem;
  transition: transform .2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: translateX(10px);
  }

  @media screen and (min-width: 1024px) {
    padding-right: 2rem;
  }
}

.currencyContainer {
  display: flex;
  align-items: center;
}

.blockchainIconWrapper {
  position: absolute;
  top: -10px;
  left: 35px;

  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(37, 182, 234);
  border: 1px solid rgb(47, 46, 46);

  svg {
    width: 16px;
    height: 16px;
  }
}

.icon {
  position: absolute;
  top: -15px;
  left: -15px;

  border-radius: 50%;
  border: 15px solid var(--clr-primary-background);
}

.currency {
  font-size: 1.25rem;
  font-weight: 500;
  @media screen and (min-width: 420px) {
    font-size: 1.5rem;
  }
}

.currencySkeleton {
  text-align: start;
}

.infoWrapper {
  text-align: end;
}

.value {
  font-size: 1.25rem;
  font-weight: 600;
  @media screen and (min-width: 420px) {
    font-size: 1.5rem;
  }
}

.title {
  color: var(--clr-secondary-text);
}
