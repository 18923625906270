.list {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    gap: 5rem;
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  max-width: 528px;

  @media screen and (min-width: 1024px) {
    width: calc((100% - 10rem) / 3);
  }

  @media screen and (min-width: 1440px) {
    align-items: start;
    gap: 1.5rem;
  }
}

.title {
  font-size: 1.5rem;

  @media screen and (min-width: 1440px) {
    font-size: 2rem;
  }
}

.description {
  font-size: 1rem;

  @media screen and (min-width: 1280px) {
    font-size: 1.25rem;
  }
}
