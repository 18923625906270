.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  z-index: 10;
  max-width: 380px;

  @media screen and (min-width: 1440px) {
    gap: 2rem;
    max-width: 460px;
  }
}

.title {
  font-size: 1.75rem;
  font-weight: 500;

  @media screen and (min-width: 340px) {
    font-size: 2rem;
  }

  @media screen and (min-width: 1024px) {
    font-size: 2.5rem;
  }
}

.subtitle {
  font-size: 0.875rem;

  @media screen and (min-width: 1024px) {
    font-size: 1rem;
  }
}

.link {
  width: max-content;
}
