.container {
  height: 80px;
  width: 100%;
  position: relative;
  overflow: hidden;

  z-index: 1;

  cursor: pointer;

  @media screen and (min-width: 560px) {
    height: 100px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    height: 70px;
    overflow: initial;
  }

  @media screen and (min-width: 1440px) {
    height: 100px;
    overflow: initial;
  }
}

.wrapperEth {
  position: absolute;
  top: 0;
  left: 0;
}

.wrapperWeth {
  position: absolute;
  top: 0;
  right: 0;
}

.wrapper {
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 115px;
  height: 50px;

  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    width: 80px;
  }

  @media screen and (min-width: 1440px) {
    width: 115px;
  }
}

.eth,
.weth {
  width: 80px;
  height: 80px;

  @media screen and (min-width: 560px) {
    width: 100px;
    height: 100px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    width: 70px;
    height: 70px;
  }

  @media screen and (min-width: 1440px) {
    width: 100px;
    height: 100px;
  }
}

.returnIcon {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
