.container {
  position: relative;
  width: 100%;
  &::after {
    position: absolute;
    content: '';
    background-image: url('/currenciesTable.svg');
    background-repeat: no-repeat;
    display: inline-block;
    width: 597px;
    height: 1234px;

    right: 0;
    top: 1100px;

    @media screen and (min-width: 560px) {
      top: 1250px;
    }

    @media screen and (min-width: 1024px) {
      top: 550px;
    }
  }
}

.poolMobile {
  position: absolute;
  content: '';
  top: 470px;
  left: 50%;
  transform: translateX(-50%);
  background-image: url('/poolMobile.svg');
  display: inline-block;
  background-repeat: no-repeat;
  width: 100%;
  max-width: 800px;

  @media screen and (min-width: 560px) {
    top: 500px;
  }

  @media screen and (min-width: 1024px) {
    display: none;
  }
}
