.title {
  font-size: 1.5rem;

  position: relative;
  display: inline-block;

  &::before {
    content: '';
    background-image: url('/icons/titleIcon.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 21px;
    height: 57px;
    display: inline-block;
    position: absolute;
    left: -11px;
    top: -10px;
  }

  @media screen and (min-width: 340px) {
    font-size: 2rem;
  }

  @media screen and (min-width: 1024px) {
    font-size: 2.5rem;
    font-weight: 500;

    &::before {
      top: -4px;
    }
  }
}
