.container {
  height: 80px;
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1;

  cursor: pointer;

  @media screen and (min-width: 560px) {
    height: 100px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    height: 70px;
    overflow: initial;
  }

  @media screen and (min-width: 1440px) {
    height: 100px;
    overflow: initial;
  }
}

.wrapperUsdc {
  position: absolute;
  top: 0;
  left: 0;
}

.wrapperYel {
  position: absolute;
  top: 0;
  right: 0;
}

.usdc,
.yel {
  width: 80px;
  height: 80px;

  @media screen and (min-width: 560px) {
    width: 100px;
    height: 100px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    width: 70px;
    height: 70px;
  }

  @media screen and (min-width: 1440px) {
    width: 100px;
    height: 100px;
  }
}

.arrowsWrapper {
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;

  width: 60px;
  height: 23px;

  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    width: 47px;
  }

  @media screen and (min-width: 1440px) {
    width: 60px;
  }
}

.tradeArrowsIcon {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
