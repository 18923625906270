.animationContent {
  position: absolute;
  top: 0;
  left: 100%;
  transform: translate(-50%, -50%);
  animation: rotate 20s linear infinite;
}

.logo {
  position: absolute;
  width: 50px;
  height: 50px;
  transform-origin: center;

  opacity: 0;
  transition: opacity 1s ease;

  &.animate {
    opacity: 1;
    animation: fadeIn 1s forwards;
  }

  @media screen and (min-width: 768px) {
    width: 60px;
    height: 60px;
  }

  @media screen and (min-width: 1024px) {
    width: 80px;
    height: 80px;
  }

  @media screen and (min-width: 1440px) {
    width: 100px;
    height: 100px;
  }
}

.logo > svg {
  width: 100%;
  height: 100%;
  display: block;
}

.logo.rotate > svg {
  rotate: 180deg;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
