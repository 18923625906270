.container {
  display: flex;
  justify-content: center;
  padding-top: 5rem;

  @media screen and (min-width: 1024px) {
    justify-content: end;
    padding-top: 7.625rem;
    padding-bottom: 10.625rem;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 565px;
  @media screen and (min-width: 1024px) {
    width: 50%;
  }
}

.titleWrapper {
  @media screen and (max-width: 1024px) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.link {
  text-align: center;
  @media screen and (min-width: 1024px) {
    width: max-content;
    z-index: 10;
  }
}

.dropsIcon {
  position: absolute;
  top: -1%;
  left: 15%;

  @media screen and (min-width: 768px) {
    left: 30%;
  }
  @media screen and (min-width: 1024px) {
    display: none;
  }
}
