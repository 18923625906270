.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  padding-top: 12rem;

  @media screen and (min-width: 560px) {
    padding-top: 18rem;
  }

  @media screen and (min-width: 1024px) {
    padding-top: 10.625rem;
    padding-bottom: 7.5rem;
  }
}

.titleWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
