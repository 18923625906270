.container {
  height: 520px;
  background-color: var(--clr-secondary-background);

  @media screen and (min-width: 1024px) {
    height: 460px;
  }
}

.title {
  font-size: 32px;
  text-align: center;

  @media screen and (max-width: 400px) {
    max-width: 260px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 40px;
  }
}

.animationPosition {
  top: 100% !important;
  left: 0 !important;
}

.circleAnimation {
  position: relative;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circleAnimation::before {
  content: '';
  width: 100px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to right, var(--clr-secondary-background), var(--clr-transparent-light-bg));
  z-index: 100;
}

.circleAnimation::after {
  content: '';
  width: 100px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(to left, var(--clr-secondary-background), var(--clr-transparent-light-bg));
  z-index: 100;
}

.gradientTop {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, var(--clr-secondary-background), var(--clr-transparent-light-bg));
  z-index: 100;
}

.gradientBottom {
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(to top, var(--clr-secondary-background), var(--clr-transparent-light-bg));
  z-index: 100;
}
