.hero {
  padding: 7.5rem 0 12.5rem 0;
  background: var(--clr-gradient-hero);
  position: relative;
  overflow: hidden;

  @media screen and (min-width: 1024px) {
    padding: 12rem 0 12.5rem 0;
  }

  @media screen and (min-width: 1440px) {
    padding: 14.75rem 0 21.5rem 0;
  }
}

.container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.75rem;

  @media screen and (min-width: 1024px) {
    gap: 2.5rem;
  }

  @media screen and (min-width: 1440px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.flaskWrapper {
  position: absolute;
  z-index: 5;

  top: 60%;
  right: -40%;

  width: 336px;
  height: 203px;
  @media screen and (min-width: 420px) {
    top: 60%;
    right: -20%;
  }

  @media screen and (min-width: 560px) {
    top: 40%;
    right: -10%;

    width: 502px;
    height: 329px;
  }

  @media screen and (min-width: 768px) {
    top: 10%;
  }

  @media screen and (min-width: 1024px) {
    top: 0;
    right: 0;

    width: 579px;
    height: 380px;
  }

  @media screen and (min-width: 1440px) {
    width: 610px;
    height: 420px;

    top: -35%;
    left: 31%;
  }
}

.potionWrapper {
  position: absolute;
  overflow: hidden;

  bottom: -22%;
  right: -15%;

  @media screen and (min-width: 420px) {
    bottom: -20%;
    right: -12%;
  }

  @media screen and (min-width: 560px) {
    bottom: -7%;
    right: 2%;
  }

  @media screen and (min-width: 1024px) {
    bottom: -10%;
    right: 0;
  }

  @media screen and (min-width: 1440px) {
    bottom: -5%;
    right: 2%;
  }
}

.potion {
  width: 290px;
  height: 177px;

  @media screen and (min-width: 560px) {
    width: 331px;
    height: 202px;
  }

  @media screen and (min-width: 1024px) {
    width: 414px;
    height: 252px;
  }
}
