.wrapper {
  position: absolute;
  z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  background: var(--clr-header-background);
  backdrop-filter: blur(20px);

  a {
    padding: 0;
  }
}

.hero {
  right: 0;
  top: 45%;
  border-radius: 30px 0px 0px 30px;

  padding: 1.25rem 1rem;

  width: 3.5rem;

  @media screen and (min-width: 1024px) {
    top: 50%;
  }

  @media screen and (min-width: 1440px) {
    border-radius: 0px 30px 30px 0px;
    left: 0;
    top: 30%;
  }
}

.footer {
  flex-direction: row;

  left: 50%;
  bottom: 0;
  transform: translateX(-50%);

  padding: 1rem 1.25rem;

  border-radius: 30px 30px 0px 0px;

  @media screen and (min-width: 1024px) {
    left: auto;
    right: 7.5rem;
  }
}

.link {
  transition: all 0.3s ease;

  &:hover {
    svg {
      path {
        fill: var(--clr-action);
      }
    }
  }
}

.icon {
  width: 24px;
  height: 24px;

  min-width: 24px;
  min-height: 24px;
}
