.iconWrapper {
  position: relative;
  display: none;

  @media screen and (min-width: 1024px) {
    width: 50%;
    display: block;
  }
}

.iconDesktop {
  position: absolute;
  z-index: 2;

  @media screen and (min-width: 1024px) {
    top: -23%;
    left: -25%;

    min-width: 542px;
    min-height: 546px;
  }

  @media screen and (min-width: 1280px) {
    top: -44%;

    min-width: 654px;
    min-height: 658px;
  }

  @media screen and (min-width: 1440px) {
    top: -76%;
    left: -50%;

    min-width: 818px;
    min-height: 823px;
  }
}

.progressBar {
  background: linear-gradient(
    to bottom,
    #eaaedc 0%,
    #b1e3eb 20%,
    #9fcff2 30%,
    #99aef5 40%,
    #a293fb 50%,
    #c389f0 60%,
    #e98ef7 70%,
    #f992d0 80%,
    #ffa3a3 90%,
    #ffe0a3 100%
  );
  position: absolute;
  transform: rotate(180deg);
  transform-origin: bottom left;
  z-index: -1;
  border-radius: 10px 10px 0 0;
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
    bottom: 25%;
    left: -5%;
    width: 12px;
  }

  @media screen and (min-width: 1280px) {
    bottom: 26%;
    left: -5%;
    width: 14px;
  }

  @media screen and (min-width: 1440px) {
    bottom: 23%;
    left: -12%;
  }
}
