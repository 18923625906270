.container {
  width: 205px;
  height: 200px;
  position: relative;
  cursor: pointer;
}

.tube {
  position: absolute;
  top: 35px;
  left: 0px;
}

.flask {
  position: absolute;
  top: 110px;
  left: 21px; //30px
}
