.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  padding-top: 15rem;
  padding-bottom: 5rem;

  @media screen and (min-width: 1024px) {
    padding-top: 7.5rem;
    padding-bottom: 11.25rem;
  }
}

.link {
  width: max-content;
  z-index: 10;
}
